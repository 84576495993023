/* BottomNav.css */

.bottom-nav {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  /* background: linear-gradient(45deg, #6d28d9 30%, #3b82f6 90%); */
  background: #ffffff;
  color: #000; /* White text color */
  padding: 5px 0;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2); 
  z-index: 1;
  padding: 10px;

}

/* Hide the BottomNav on larger screens */
@media (min-width: 768px) {
  .bottom-nav {
      display: none; /* Hide on screens larger than or equal to 768px */
  }
}

.bottom-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: #000; /* White icon and text color */
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
  
}

.bottom-nav-item:hover {
  color: #00bfa5; /* Change color on hover */
}

.bottom-nav-item .icon {
  font-size: 24px; /* Icon size */
  margin-bottom: 5px;
}

.label {
  font-size: 12px; /* Label size */
  font-weight: 500; /* Slightly bold text for label */
  margin-top: 3px; /* Add space between icon and label */
}

@media (max-width: 600px) {
  .bottom-nav-item .icon {
      font-size: 20px; /* Smaller icons on mobile */
  }

  .label {
      font-size: 10px; /* Smaller labels on mobile */
  }
}
