.floating-whatsapp {
    position: fixed;
    bottom: 70px;
    right: 1px;
    background-color: #25d366;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 1000;
    transition: transform 0.3s ease;
  }
  
  .floating-whatsapp:hover {
    transform: scale(1.1);
  }
  